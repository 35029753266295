/* eslint-disable */
export const modalMixin = {
    methods: {
      showModal(e, modalName) {
        try {
          e.preventDefault();
        } catch (error) {
          e = document.getElementById(e);
        }
        try {
          const { id: targetId } = e.target;
          this.$root.$emit("bv::show::modal", `${modalName}`, `#${targetId}`);
        } catch (error) {
          this.$root.$emit("bv::show::modal", `${modalName}`);
        }
      }
    }
  };
  
  export default modalMixin;
  