<template>
  <b-modal v-if='i18n["tt-outside-state"]' class="bmodal" size="lg" id="outsideStateModal" ref="modalOutsideState"
    :cancel-title="i18n['tt-outside-state'].tcCancel" :no-close-on-backdrop="noCloseOnBackdrop"
    :ok-title="i18n['tt-outside-state'].tcOk" :title="i18n['tt-outside-state'].tcAddACampFromAnotherState"
    @ok="handleClickOk" @cancel="handleClickCancel">
    <div class="formdiv">
      <h6>{{ i18n['tt-outside-state'].tcSelectTheDesiredStateThenSelectTheAppropriateCamp }}</h6>
      <b-form>
        <b-row>
          <b-col sm="8">
            <label>{{ i18n['tt-outside-state'].tcSelectAState }}</label>
            <b-form-select :options="outsideStateList(homeState.value)" v-model="outside_state.key"
              @change="handleStateChange($event)"></b-form-select>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="8">&nbsp;</b-col>
        </b-row>
        <b-row v-if="isGuid(outside_state.key)">
          <b-col sm="8">
            <label>{{ i18n['tt-outside-state'].tcSelectACamp }}</label>
            <b-form-select :options="outsideStateCampList" v-model="outside_camp.key"
              @change="handleCampChange($event)"></b-form-select>
          </b-col>
        </b-row>
      </b-form>
      <br />
      <h6 v-if="outside_camp.name.length > 0">
        {{ i18n['tt-outside-state'].tcClickOkToAdd }}
        <i style="color: red;">{{ outside_camp.name }}</i> {{ i18n['tt-outside-state'].tcToTheCampList }}
      </h6>
    </div>
  </b-modal>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions } from "vuex"
import { numericMixin } from '@/mixins/numericMixin'

export default {
  name: "OutsideState",
  mixins: [numericMixin],
  props: {
    fiscalYear: {
      type: Number,
      default: 0
    },
    i18n: {
      type: Object,
      default: () => {
        return {
          'tt-outside-state': {
            tcAddACampFromAnotherState: 'Add a Camp from Another State',
            tcCancel: 'Cancel',
            tcClickOkToAdd: 'Click OK to add',
            tcOk: 'Ok',
            tcSelectACamp: 'Select a Camp',
            tcSelectAState: 'Select a State',
            tcSelectTheDesiredStateThenSelectTheAppropriateCamp: 'Select the desired state, then select the appropriate camp.',
            tcToTheCampList: 'to the camp list.'
          }
        }
      }
    }
  },
  data() {
    return {
      noCloseOnBackdrop: true,
      activity: {},
      outside_state: { key: "", name: "", number: "" },
      outside_camp: { key: "", name: "", number: "" }
    };
  },
  created() {},
  computed: {
    ...mapGetters({
      activitySelected: "activityModule/activitySelected",
      completeOutsideCampObject: "campModule/completeOutsideCampObject",
      completeOutsideStateObject: "remittanceModule/completeOutsideStateObject",
      getEmptyActivity: "activityModule/getEmptyActivity",
      homeState: "campModule/homeState",
      outsideStateCampList: "campModule/outsideStateCampList",
      outsideStateList: "remittanceModule/outsideStateList"
    })
  },
  methods: {
    ...mapActions({
      addOutsideStateCampToCampList: "campModule/addOutsideStateCampToCampList",
      getCampsFromOutsideState: "campModule/getCampsFromOutsideState"
    }),
    closeModal() {
      this.$refs.modalOutsideState.hide();
    },
    handleClickCancel() {
      this.outside_state = { key: "", name: "", number: "" };
      this.outside_camp = { key: "", name: "", number: "" };
      this.closeModal();
    },
    async handleClickOk() {
      await this.addOutsideStateCampToCampList(this.outside_camp);
      this.$emit("selectNewCamp", this.outside_camp.key);
      this.closeModal();
    },
    async handleSaveClick(act_status) {
      this.closeModal();
    },
    async handleCampChange(evt) {
      this.outside_camp = await this.completeOutsideCampObject(
        this.outside_camp
      );
      this.outside_camp.name = `${this.outside_camp.name} - ${this.outside_state.name}`;
    },
    async handleStateChange(evt) {
      await this.getCampsFromOutsideState(evt);
      this.outside_state = this.completeOutsideStateObject(this.outside_state);
    }
  }
};
</script>

<style scoped>
.xbuttons {
  margin-top: 30px;
}

label {
  margin-bottom: 0;
  font-weight: 600;
}

.mr20 {
  margin-right: 20px;
}
</style>
