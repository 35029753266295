<template>
  <div class="template-1">
    <header class="page-header container">
      <h1>{{ translations.tcBankActivityReport }}</h1>
    </header>
    <div v-if="!allowPage">
      <security-banner :i18n="translations.components"></security-banner>
    </div>
    <div v-if="allowPage">
      <div class="template-2">
        <b-container>
          <b-row>
            <b-col sm="4">
              <span v-if="(!isHeadquarters && !isState) || (stateOrCamp === 'c' && !onStatePage)">{{ currentCamp.text }}
                {{
                  translations.tcCamp }} - {{ currentCamp.value }}<br /></span>
              <b-link class="ba_template" :href="ba_template" type="application/pdf" target="_blank" download>
                {{ translations.tcBankingActivityTemplate }}
              </b-link>
            </b-col>
            <b-col sm="4">
              Camp
              <Location v-if="isVisible" @camp_change="handleCampChange($event)" :locations="campListWithState"
                :i18n="translations.components" :remittance="false" />
            </b-col>
            <b-col sm="2">
              <div class="audit">
                {{ translations.tcAuditPeriod }}
                <b-form-select v-model="selectedFiscalYear" :options="fiscalYears"
                  @change="handleFiscalYearChange($event)"></b-form-select>
              </div>
            </b-col>
          </b-row>
        </b-container>
        <b-alert :show="dismissCountDown" dismissible :variant="alertVariant" @dismissed="dismissCountDown = 0"
          @dismiss-count-down="countDownChanged">{{ alertMessage }}</b-alert>
        <b-container>
          <b-row>
            <b-col sm="4">
              <h4 style="margin-top: 30px">{{ translations.tcMonthlyActivity }}</h4>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12">
              <hr />
            </b-col>
          </b-row>
          <b-row class="hdr flex-nowrap">
            <b-col sm="5" md="2" lg="2">
              <b>{{ translations.tcPeriod }}</b>
            </b-col>
            <b-col sm="3" md="2" lg="2">
              <b>{{ translations.tcStatus }}</b>
            </b-col>
            <b-col sm="2" md="2" lg="2">
              <b>{{ translations.tcBeginning }}</b>
            </b-col>
            <b-col sm="2" md="2" lg="2">
              <b>{{ translations.tcEnding }}</b>
            </b-col>
            <b-col sm="0" md="4" lg="4" class="d-none d-sm-block">
              <b>{{ translations.tcToggleStatus }}</b>
            </b-col>
          </b-row>
          <b-row class="up flex-nowrap display-row" v-for="(act, index) in activities" :key="`act-${index}`">
            <b-col sm="5" md="2" lg="2" class="mb10">{{ formatDate(act.date_formatted) }}
              <b-row class="flex-nowrap d-lg-none d-xl-block btnrsprow">
                <b-col>
                  <b-button class="mb10 btnrsp" size="sm" v-if="monthStatus(act) !== 'Unopened'" variant="warning"
                    @click="handleCheckBoxChange($event, act)">{{ monthStatus(act) === translations.tcPending ?
                      translations.tcChangeToSubmitted : translations.tcChangeToPending }}</b-button>
                </b-col>
              </b-row>
            </b-col>
            <b-col sm="3" md="2" lg="2" class="mb10">
              <span v-if="(act.prior_month_status !== 1 && act.prior_month_status !== -1) ||
                (act.bac_status === 0 && act.bac_end_balance === 0)
                ">
                {{ translations.tcUnopened }}
              </span>
              <b-link v-else :class="{
                unopened: act.prior_month_status !== 1 && act.prior_month_status !== -1,
              }" href="#" v-b-modal.activityReportModal
                @click.prevent="showTheModal($event, 'activityReportModal', act.index)">{{ monthStatus(act) }}</b-link>
            </b-col>
            <b-col sm="2" md="2" lg="2" class="mb10">{{ act.bac_beg_balance }}</b-col>
            <b-col sm="2" md="2" lg="2" class="mb10">{{ act.bac_end_balance }}</b-col>
            <b-col sm="0" md="4" lg="4" v-if="isHeadquarters && iCanSeeAny(secured_controls)" class="d-none d-sm-block">
              <b-button class="mb10" size="sm" v-if="monthStatus(act) !== 'Unopened'" variant="warning"
                @click="handleCheckBoxChange($event, act)">{{ monthStatus(act) === translations.tcPending ?
                  translations.tcChangeToSubmitted : translations.tcChangeToPending }}</b-button>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <br />
      <div class="uploads template-3">
        <b-container>
          <b-row style="margin-top: 30px">
            <b-col sm="4">
              <h4>{{ translations.tcUploadedFiles }}</h4>
            </b-col>
            <b-col sm="6" class="mt30">
              <b-button v-if="allowUploads" size="sm" variant="info" @click="resetResponseMessage('')"
                v-b-modal.uploadFileModal>
                {{ translations.tcUploadBankStatement }}
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12">
              <hr />
            </b-col>
          </b-row>
          <b-row class="hdr">
            <b-col sm="2">
              <b>{{ translations.tcPeriod }}</b>
            </b-col>
            <b-col sm="6">
              <b>{{ translations.tcFile }}</b>
            </b-col>
          </b-row>
          <b-row class="upl display-row" v-for="file in uploadedFiles" :key="file.bau_key">
            <b-col sm="2">{{ formatDate(file.date_formatted) }}</b-col>
            <b-col sm="6">{{ file.bau_file_name }}</b-col>
            <b-col sm="2">
              <b-button class="mb10" size="sm" variant="danger" @click="handleDeleteRequest(file.bau_key)">
                {{ translations.tcDelete }}
              </b-button>
            </b-col>
            <b-col sm="1">
              <b-link :href="file.bau_file_name_full" download target="_blank">{{ translations.tcView }}</b-link>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="1">
              <b-button class="btn btn-tertiary btn-w-med mtb30" @click="handleBackClick">
                {{ translations.tcBack }}
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <Report @bankingActivitySaveFailed="handleSaveFailure" :fiscalYear="selectedFiscalYear"
        :i18n="translations.components" />
      <UploadFile :fiscalYear="selectedFiscalYear" @refresh_upload_list="refreshUploadFiles"
        :i18n="translations.components" />
      <br />
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions } from 'vuex'
import { alertMixin } from '@/mixins/alertMixin'
import constantData from '@/json/data.json'
import Loader from '@/components/treasury-tools/loader'
import Location from '@/components/treasury-tools/location'
import Report from '@/components/treasury-tools/activity_report'
import SecurityBanner from '@/components/SecurityBanner'
import { translationMixin } from '@/mixins/translationMixin'
import UploadFile from '@/components/treasury-tools/upload'

export default {
  components: {
    Loader,
    Location,
    Report,
    SecurityBanner,
    UploadFile,
  },
  mixins: [alertMixin, translationMixin],
  name: 'activity',
  props: {},
  data() {
    return {
      dateFormatShort: { year: 'numeric', month: 'long' }, // `May 2021`  OR  `mayo de 2021`,
      secured_controls: {
        camp_banking_change_status: '2e974b12-2893-4dba-8f46-64ab6c5e700a',
        toggle_monthly_banking_activity_status_button: '6dda3fd1-6809-4e08-a110-960c36067d77',
        state_bank_activity_camp_dropdown_list: 'b82963d0-a7dd-427c-b141-b9b991a0fc78',
        camp_bank_activity_camp_dropdown_list: '3e80c3fe-a3c4-4111-8f7d-19bae96d1db5',
      },
      secured_tile_view_controls: {
        treasury_monthly_camp_banking_tile: '27008401-c031-4593-a68b-0b06e0c5f378',
        treasury_monthly_state_banking_tile: 'ddcd1cb6-3394-4791-a1b6-450a62b6a244',
      },
      selectedActivity: {},
      selectedFiscalYear: null,
      translations: {},
    }
  },
  async created() {
    await this.page_load()
  },
  computed: {
    ...mapGetters({
      activities: 'activityModule/activities',
      currentCamp: 'campModule/currentCamp',
      campListWithState: 'campModule/campListWithState',
      fiscalYears: 'activityModule/getFiscalYearsForDropdown',
      getMaxFiscalYear: 'activityModule/getMaxFiscalYear',
      getUserKey: 'userModule/getUserKey',
      homeState: 'campModule/homeState',
      iCanSee: 'user/iCanSee',
      iCanSeeAny: 'user/iCanSeeAny',
      isHeadquarters: 'userModule/isHeadquarters',
      isState: 'userModule/isState',
      prefCulture: 'user/userPreferredCulture',
      stateCampList: 'campModule/stateCampList',
      stateLevel: 'campModule/stateLevel',
      stateOrCamp: 'campModule/stateOrCamp',
      uploadedFiles: 'activityModule/uploadedFiles',
      userId: 'user/userId',
    }),
    allowPage() {
      return (
        this.iCanSee(this.secured_tile_view_controls.treasury_monthly_state_banking_tile) ||
        this.iCanSee(this.secured_tile_view_controls.treasury_monthly_camp_banking_tile)
      )
    },
    allowUploads() {
      let bstTally = 0
      this.activities.map((bst) => {
        bstTally += bst.bac_status
      })
      return bstTally > 0
    },
    isVisible() {
      return (
        (this.stateOrCamp === 's' && this.iCanSee(this.secured_controls.state_bank_activity_camp_dropdown_list)) ||
        (this.stateOrCamp === 'c' && this.iCanSee(this.secured_controls.camp_bank_activity_camp_dropdown_list))
      )
    },
    ba_template() {
      return constantData.banking_document_prefix + 'MonthlyReportCampBankingActivity.pdf'
    },
    onStatePage() {
      return this.$route.path.split('/')[2].toLowerCase() === 'state'
    },
  },
  methods: {
    ...mapActions({
      deleteUploadedFile: 'activityModule/deleteUploadedFile',
      getAvailableMonths: 'activityModule/getAvailableMonths',
      getUploadedFiles: 'activityModule/getUploadedFiles',
      resetResponseMessage: 'activityModule/resetResponseMessage',
      retrieveFiscalYears: 'activityModule/retrieveFiscalYears',
      setCurrentActivity: 'activityModule/setCurrentActivity',
      setCurrentCamp: 'campModule/setCurrentCamp',
      setLoadingStatus: 'menu/setLoadingStatus',
      updateActivityStatusById: 'activityModule/updateActivityStatusById',
    }),
    formatDate(givenDateString) {
      if (!givenDateString) return givenDateString
      givenDateString = givenDateString.replace(' ', ' 1 ') // insert a 1 to ensure proper date handling
      return this.returnFormattedDate(givenDateString, this.dateFormatShort)
    },
    async page_load() {
      try {
        this.setLoadingStatus(true)
        await Promise.all([
          this.getViewTranslations(),
          this.retrieveFiscalYears(),
          this.getComponentTranslations('report', 'security-banner', 'tt-location', 'tt-outside-state', 'upload-file'),
        ]).then((results) => {
          const componentTranslations = results[2]
          this.$set(this.translations, 'components', componentTranslations)
        })
        // set current camp depending on stateOrCamp
        if (this.stateOrCamp === 's') {
          const cc = [this.stateLevel].map((cmp) => {
            return {
              key: cmp.cmp_org_key,
              text: cmp.camp_name,
              value: cmp.camp_number,
            }
          })[0]
          this.setCurrentCamp(cc)
        }
        this.selectedFiscalYear = await this.getMaxFiscalYear
        await this.getUploadedFiles({
          org_key: this.stateOrCamp === 'c' ? this.currentCamp.key : this.homeState.value,
          bac_year: this.selectedFiscalYear,
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    activitySum(activity) {
      return (
        '$' +
        (
          parseFloat(activity.beginning) * 1.0 +
          parseFloat(activity.scr_deposits) * 1.0 +
          parseFloat(activity.gen_deposits) * 1.0 +
          parseFloat(activity.sent_to_hq) * 1.0 +
          parseFloat(activity.sent_to_state) * 1.0 +
          parseFloat(activity.merchandise) * 1.0 +
          parseFloat(activity.other) * 1.0
        ).toFixed(2)
      )
    },
    handleBackClick() {
      this.$router.push({ path: `/officers/camp/treasury-tools/${this.stateOrCamp}` })
    },
    async handleCampChange(evt) {
      if (evt === this.homeState.value) {
        await this.setCurrentCamp({
          key: this.homeState.value,
          text: this.homeState.display_text,
          value: this.homeState.state_code,
        })
      } else {
        let ccx = this.stateCampList.find((cx) => cx.cmp_org_key === evt)
        await this.setCurrentCamp({ key: ccx.cmp_org_key, text: ccx.camp_name, value: ccx.camp_number })
      }
      await this.handleFiscalYearChange(this.selectedFiscalYear)
    },
    async handleCheckBoxChange(evt, act) {
      act.bac_status = !act.bac_status
      await this.updateActivityStatusById(act)
      await this.handleFiscalYearChange(this.selectedFiscalYear)
    },
    async handleDeleteRequest(baukey) {
      await this.deleteUploadedFile({
        bau_key: baukey,
        bac_year: this.selectedFiscalYear,
        ind_key: this.userId,
      }).then(() => {
        this.showAlert('info', `${this.translations.tcFileDeleted}.`)
      })
    },
    async handleFiscalYearChange(evt) {
      try {
        this.setLoadingStatus(true)
        await (async () => {
          await this.getAvailableMonths({
            org_key: this.currentCamp.key,
            fy: evt,
          })
          await this.getUploadedFiles({
            org_key: this.currentCamp.key,
            bac_year: evt,
          })
        })()
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    handleSaveFailure() {
      this.showAlert('danger', `${this.translations.tcSaveProcessFailed}.`)
    },
    monthStatus(mn) {
      var status = mn.bac_status === 0 ? (mn.bac_key === null ? this.translations.tcUnopened : this.translations.tcPending) : this.translations.tcSubmitted
      return status
    },
    async refreshUploadFiles() {
      await this.getUploadedFiles({
        org_key: this.currentCamp.key,
        bac_year: this.selectedFiscalYear,
      })
    },
    showTheModal(e, modalName, actid) {
      e.preventDefault()
      this.selectedActivity = this.activities.find((sa) => {
        return sa.index === actid
      })
      this.setCurrentActivity(this.selectedActivity)
      const { id: targetId } = e.target
      this.$root.$emit('bv::show::modal', `${modalName}`, `#${targetId}`)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
@import '@/styles/bootstrap/b-button.scss';

.mtb30 {
  margin: 30px 0;
}

.display-row {
  border-bottom: 1px solid #cccccc;
  padding-top: 8px;
  padding-bottom: 8px;
}

.uploads {
  margin-top: 30px;
}

.dollarsign {
  position: relative;
  height: 0;
  left: -14px;
}

.btnrsprow {
  margin-bottom: 40px;
}

.btnrsp {
  min-width: 150px;
  display: block;
  position: absolute;
  padding: 0.25rem 0.5rem;
  border: none;
  border-radius: 0;
  color: #ffffff;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 100;
  letter-spacing: 0px;
  line-height: 1.5;
  text-transform: uppercase;
}

.nonew {
  padding: 30px;
}

.dtext,
.shorttext,
.mediumtext {
  line-height: 13px;
  font-size: 12px;
}

.rtext {
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  margin-top: 10px;
}

.shorttext {
  margin-top: 8px;
}

.mediumtext {
  margin-top: 4px;
}

.brow {
  line-height: 36px;
}

.editing {
  background-color: #35b130;
  color: #ffffff;
  border-radius: 6px;
}

.subtitle {
  margin: 10px 0;
}

@import '@/styles/settings.scss';

.membership {
  .page-body {
    margin-top: 60px;

    h2 {
      margin-bottom: 36px;
    }
  }

  .section-1,
  .section-2 {
    margin-bottom: 52px;

    >.row {
      margin-bottom: 40px;
    }

    .t-container {
      @include breakpoint(sm) {
        margin-bottom: 1rem;
      }
    }

    .col {
      @include breakpoint(sm) {
        padding-left: 15px !important;
        padding-right: 15px !important;
      }

      &:first-of-type {
        padding-right: 23px;
      }

      &:last-of-type {
        padding-left: 23px;

        .number-card:last-of-type {
          margin-bottom: 0;
        }
      }

      table {
        width: 100% !important;
      }
    }
  }

  .section-3 {
    margin-bottom: 60px;

    .col {
      &:nth-child(2n-1) {
        padding-right: 23px;
      }

      &:nth-child(2n) {
        padding-left: 23px;
      }
    }

    @include breakpoint(sm) {
      .col {
        height: 100%;
        max-width: none;
        width: 100%;
        flex: 0 0 100%;
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
    }
  }
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.audit {

  // margin-top: -24px;
  @include breakpoint(mobile) {
    margin-top: 5px;
  }
}
</style>
