<template>
  <b-modal class="bmodal" size="lg" hide-footer id="activityReportModal" ref="modalReportActivity" :title="reportTitle"
    :no-close-on-backdrop="noCloseOnBackdrop" :cancel-title='i18n.report.tcCancel' :ok-title='i18n.report.tcOk'
    @show="modalHandleShow" @hidden="modalHandleHidden">
    <div class="formdiv">
      <h4>{{ i18n.report.tcNote }}</h4>
      <b-form>
        <b-row class="odd">
          <b-col sm="8">{{ i18n.report.tcBankAccount }}</b-col>
          <b-col sm="3" class="tar">
            <b-input :readonly="readOnly" v-if="monthStatus() < 2" class="activity-input" @keypress="onlyNumber($event)"
              v-model="activity.bac_beg_balance" @change="handleAmountChange($event, 0)"></b-input>
            <span v-if="monthStatus() === 2">{{ activity.bac_beg_balance }}</span>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="8" class="section-header">{{ i18n.report.tcDeposits }}</b-col>
        </b-row>
        <b-row class="odd">
          <b-col sm="8">{{ i18n.report.tcScriptureFundsAccount }}</b-col>
          <b-col sm="3" class="tar">
            <b-input v-if="monthStatus() < 2" class="activity-input" @click="handleAmountInputClick($event)"
              @blur="handleAmountInputLeave($event)" @keypress="onlyNumber($event)" v-model="activity.bac_sfd_deposit"
              @change="handleAmountChange($event, 1)"></b-input>
            <span v-if="monthStatus() === 2">{{ activity.bac_sfd_deposit }}</span>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="8">{{ i18n.report.tcBarnabasFundsDeposited }}</b-col>
          <b-col sm="3" class="tar">
            <b-input v-if="monthStatus() < 2" class="activity-input" @click="handleAmountInputClick($event)"
              @blur="handleAmountInputLeave($event)" @keypress="onlyNumber($event)" v-model="activity.bac_bfd_deposit"
              @change="handleAmountChange($event, 2)"></b-input>
            <span v-if="monthStatus() === 2">{{ activity.bac_bfd_deposit }}</span>
          </b-col>
        </b-row>
        <b-row class="odd">
          <b-col sm="8">{{ i18n.report.tcCampGeneralFundsAccount }}</b-col>
          <b-col sm="3" class="tar">
            <b-input v-if="monthStatus() < 2" class="activity-input" @click="handleAmountInputClick($event)"
              @blur="handleAmountInputLeave($event)" @keypress="onlyNumber($event)" v-model="activity.bac_gfd_deposit"
              @change="handleAmountChange($event, 3)"></b-input>
            <span v-if="monthStatus() === 2">{{ activity.bac_gfd_deposit }}</span>
          </b-col>
        </b-row>
        <b-row >
          <b-col sm="8" class="section-header">{{ i18n.report.tcExpenses }}</b-col>
        </b-row>
        <b-row class="odd">
          <b-col sm="8">{{ i18n.report.tcScriptureFundHeadquarter }}</b-col>
          <b-col sm="3" class="tar">
            <b-input v-if="monthStatus() < 2" class="activity-input red" @click="handleAmountInputClick($event)"
              @blur="handleAmountInputLeave($event)" @keypress="onlyNumber($event)" v-model="activity.bac_sfh_503_sent"
              @change="handleAmountChange($event, 4)"></b-input>
            <span class="red" v-if="monthStatus() === 2">{{ activity.bac_sfh_503_sent }}</span>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="8">{{ i18n.report.tcBarnabasFundsHeadquarters }}</b-col>
          <b-col sm="3" class="tar">
            <b-input v-if="monthStatus() < 2" class="activity-input red" @click="handleAmountInputClick($event)"
              @blur="handleAmountInputLeave($event)" @keypress="onlyNumber($event)" v-model="activity.bac_bfh_sent"
              @change="handleAmountChange($event, 5)"></b-input>
            <span class="red" v-if="monthStatus() === 2">{{ activity.bac_bfh_sent }}</span>
          </b-col>
        </b-row>
        <b-row class="odd">
          <b-col sm="8">{{ i18n.report.tcScriptureFundsSentToState }}</b-col>
          <b-col sm="3" class="tar">
            <b-input v-if="monthStatus() < 2" class="activity-input red" @click="handleAmountInputClick($event)"
              @blur="handleAmountInputLeave($event)" @keypress="onlyNumber($event)" v-model="activity.bac_sfs_stc_sent"
              @change="handleAmountChange($event, 6)"></b-input>
            <span class="red" v-if="monthStatus() === 2">{{ activity.bac_sfs_stc_sent }}</span>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="8">{{ i18n.report.tcBarnabasFundsState }}</b-col>
          <b-col sm="3" class="tar">
            <b-input v-if="monthStatus() < 2" class="activity-input red" @click="handleAmountInputClick($event)"
              @blur="handleAmountInputLeave($event)" @keypress="onlyNumber($event)" v-model="activity.bac_bfs_stc_sent"
              @change="handleAmountChange($event, 7)"></b-input>
            <span class="red" v-if="monthStatus() === 2">{{ activity.bac_bfs_stc_sent }}</span>
          </b-col>
        </b-row>
        <b-row class="odd">
          <b-col sm="8">
            {{ i18n.report.tcPurchaseGideon }}
            <i>{{ i18n.report.tcIncludeReimbursements }}</i>
          </b-col>
          <b-col sm="3" class="tar">
            <b-input v-if="monthStatus() < 2" class="activity-input red" @click="handleAmountInputClick($event)"
              @blur="handleAmountInputLeave($event)" @keypress="onlyNumber($event)" v-model="activity.bac_prh_purchase"
              @change="handleAmountChange($event, 8)"></b-input>
            <span class="red" v-if="monthStatus() === 2">{{ activity.bac_prh_purchase }}</span>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="8">{{ i18n.report.tcOtherCampGeneral }}</b-col>
          <b-col sm="3" class="tar">
            <b-input v-if="monthStatus() < 2" class="activity-input red" @click="handleAmountInputClick($event)"
              @blur="handleAmountInputLeave($event)" @keypress="onlyNumber($event)" v-model="activity.bac_gfd_expense"
              @change="handleAmountChange($event, 9)"></b-input>
            <span class="red" v-if="monthStatus() === 2">{{ activity.bac_gfd_expense }}</span>
          </b-col>
        </b-row>
        <b-row class="odd">
          <b-col sm="8">{{ i18n.report.tcEndingBankAccount }}</b-col>
          <b-col sm="3">
            <b class="tot">{{ activityTotal }}</b>
          </b-col>
        </b-row>
      </b-form>
      <dl>
        <dt>{{ i18n.report.tcScriptureFundsChurch }}</dt>
        <dt>{{ i18n.report.tcBarnabasFundsDetail }}</dt>
        <dt>{{ i18n.report.tcGeneralFundsNonScripture }}</dt>
      </dl>
    </div>
    <div class="xbuttons">
      <b-button variant="secondary" class="float-left" @click="handleClickCancel">{{ i18n.report.tcCancel }}</b-button>
      <b-button v-if="
        activity.bac_status !== 1 &&
        iCanSee(secured_pending_activity_submit_controls.submit_treasury_tool_pending_activity_button)
      " variant="primary" class="float-right" @click="handleSaveClick(true)">{{ i18n.report.tcSubmit }}</b-button>
      &nbsp;
      <b-button v-if="activity.bac_status !== 1" variant="outline-primary" class="float-right mr20"
        @click="handleSaveClick(false)">{{ i18n.report.tcSave }}</b-button>
    </div>
  </b-modal>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions } from 'vuex'
import { numericMixin } from '../../mixins/numericMixin'

export default {
  name: 'Report',
  props: {
    fiscalYear: {
      type: Number,
      default: 0,
    },
    i18n: {
      type: Object,
      default: () => {
        return {
          report: {
            tcBankAccount: 'Beginning Bank Accounts Balance (Combine all accounts)',
            tcBarnabasFundsDeposited: 'Barnabas Funds Deposited to your Bank Account',
            tcBarnabasFundsDetail: 'Barnabas Funds: Barnabas Fund Camp Offerings, Barnabas Fund State Conventions.',
            tcBarnabasFundsHeadquarters: 'Barnabas Funds Remitted To Headquarters',
            tcBarnabasFundsState: 'Barnabas Funds Remitted to State (State Conventions)',
            tcCampBankingActivity: ' Camp Banking Activity for the month of ',
            tcCampGeneralFundsAccount: 'Camp General Funds Deposited to your Bank Account',
            tcCancel: 'Cancel',
            tcDeposits: 'Deposits:',
            tcEndingBankAccount: 'Ending Bank Accounts Balance (Combine all accounts)',
            tcExpensed: 'Expenses:',
            tcGeneralFundsNonScripture: 'Camp General Funds: Non-Scripture, funds to be used for your camp function.',
            tcIncludeReimbursements: '(include reimbursements to camp members)',
            tcNote: 'NOTE: This report must exactly match your camp bank statement',
            tcOk: 'Ok',
            tcOtherCampGeneral: 'Other Camp General Fund Expense for the Camp',
            tcPurchaseGideon: ' Purchase of Gideon HQ merchandise',
            tcSave: 'Save',
            tcScriptureFundsAccount: 'Scripture Funds Deposited to your Bank Account',
            tcScriptureFundHeadquarter: 'Scripture Funds Remitted to Headquarters',
            tcScriptureFundsSentToState: 'Scripture Funds Remitted to State (State Conventions and local rallies)',
            tcScriptureFundsChurch:
              'Scripture Funds: Church, GideonCard, Faith Fund, Auxiliary, Other Designated.',
            tcSubmit: 'Submit',
          },
        }
      },
    },
  },
  mixins: [numericMixin],
  data() {
    return {
      noCloseOnBackdrop: true,
      activity: {},
      activity_bac_beg_balance: 0.0,
      secured_pending_activity_submit_controls: {
        submit_treasury_tool_pending_activity_button: 'f338850d-b0c4-4c2c-b607-3fc9b1c5afaf',
      },
    }
  },
  computed: {
    ...mapGetters({
      activitySelected: 'activityModule/activitySelected',
      beginningBalance: 'activityModule/activitySelectedBegBalance',
      getEmptyActivity: 'activityModule/getEmptyActivity',
      iCanSee: 'user/iCanSee',
    }),
    activityTotal() {
      return (
        parseFloat(this.activity.bac_beg_balance === '' ? 0 : this.activity.bac_beg_balance) +
        parseFloat(this.activity.bac_bfd_deposit === '' ? 0 : this.activity.bac_bfd_deposit) +
        parseFloat(this.activity.bac_sfd_deposit === '' ? 0 : this.activity.bac_sfd_deposit) +
        parseFloat(this.activity.bac_gfd_deposit === '' ? 0 : this.activity.bac_gfd_deposit) -
        parseFloat(this.activity.bac_bfh_sent === '' ? 0 : this.activity.bac_bfh_sent) -
        parseFloat(this.activity.bac_bfs_stc_sent === '' ? 0 : this.activity.bac_bfs_stc_sent) -
        parseFloat(this.activity.bac_sfh_503_sent === '' ? 0 : this.activity.bac_sfh_503_sent) -
        parseFloat(this.activity.bac_sfs_stc_sent === '' ? 0 : this.activity.bac_sfs_stc_sent) -
        parseFloat(this.activity.bac_prh_purchase === '' ? 0 : this.activity.bac_prh_purchase) -
        parseFloat(this.activity.bac_gfd_expense === '' ? 0 : this.activity.bac_gfd_expense)
      ).toFixed(2)
    },
    readOnly() {
      const mayOrNot = this.activity.date_formatted.substr(0, 3)
      return mayOrNot !== 'May' || (mayOrNot === 'May' && parseFloat(this.beginningBalance) > 0.0)
    },
    reportTitle() {
      this.activity = this.activitySelected
      return ` ${this.i18n.report.tcCampBankingActivity} ${this.activity.date_formatted}`
    },
  },
  methods: {
    ...mapActions({
      resetCurrentActivity: 'activityModule/resetCurrentActivity',
      updateBankingActivity: 'activityModule/updateBankingActivity',
    }),
    monthStatus() {
      return this.activity.bac_status === 0 ? (this.activity.bac_key === null ? 0 : 1) : 2
    },
    closeModal() {
      this.$refs.modalReportActivity.hide()
    },
    modalHandleShow() { },
    modalHandleHidden() { },
    handleAmountInputLeave(evt) {
      if (evt.target.value === '') {
        evt.target.value = '0.00'
      }
    },
    handleAmountChange(evt, ord) {
      evt = parseFloat(evt).toFixed(2) === 'NaN' ? 0 : evt
      switch (ord) {
        case 0:
          this.activity.bac_beg_balance = parseFloat(evt).toFixed(2)
          break
        case 1:
          this.activity.bac_sfd_deposit = parseFloat(evt).toFixed(2)
          break
        case 2: 
          this.activity.bac_bfd_deposit = parseFloat(evt).toFixed(2)
          break;
        case 3:
          this.activity.bac_gfd_deposit = parseFloat(evt).toFixed(2)
          break
        case 4:
          this.activity.bac_sfh_503_sent = parseFloat(evt).toFixed(2)
          break
        case 5:
          this.activity.bac_bfh_sent = parseFloat(evt).toFixed(2)
          break
        case 6:
          this.activity.bac_sfs_stc_sent = parseFloat(evt).toFixed(2)
          break
        case 7:
          this.activity.bac_bfs_stc_sent = parseFloat(evt).toFixed(2)
          break
        case 8:
          this.activity.bac_prh_purchase = parseFloat(evt).toFixed(2)
          break
        case 9:
          this.activity.bac_gfd_expense = parseFloat(evt).toFixed(2)
          break
      }
    },
    async handleClickCancel() {
      let date_hold = this.activity.date_formatted
      this.activity = this.getEmptyActivity
      this.activity.date_formatted = date_hold
      await this.resetCurrentActivity()
      this.closeModal()
    },
    async handleSaveClick(act_status) {
      try {
        this.activity.bac_status = act_status
        if (
          (await this.updateBankingActivity({
            activity: this.activity,
            fiscalYear: this.fiscalYear,
          })) === false
        ) {
          throw 'Banking activity update failed.'
        }
      } catch (e) {
        console.error(e)
        this.$emit('bankingActivitySaveFailed')
      }
      this.closeModal()
    },
  },
}
</script>

<style scoped>
i {
  font-size: 12px;
}

label {
  margin: 20px 0 1px 0;
}

.form-control[readonly] {
  background-color: #636363;
  color: #ffffff;
  opacity: 1;
}

.tar {
  text-align: right;
}

.row {
  line-height: 42px;
}

.red {
  color: #ff0000;
}

.err {
  margin: 20px;
  color: #ff0000;
  font-weight: 600;
}

.mr20 {
  margin-right: 20px;
}

.odd {
  background-color: #eeeeee;
}

.tot {
  margin-left: 92px;
  font-size: 18px;
}

.activity-input {
  margin-top: 2px;
  text-align: right;
}

h4 {
  font-style: italic;
  color: red;
  font-size: 14px;
}

dl {
  margin-top: 30px;
}

dt {
  font-size: 14px;
}
.section-header{
  font-weight: bold;
  text-decoration: underline;
  font-size: 18px;
}
</style>
