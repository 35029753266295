<template>
  <div class="smallrow">
    <b-form-select v-if="showDropdown === true" :options="locations" v-model="currentCamp.key"
      @change="handleCampChange($event)"></b-form-select>
    <span v-if="remittance">{{ i18n['tt-location'].tcCampWithdrawnFundsMessage }}</span>
    <p class="po" v-if="outside">
      <b-link class="outside" v-b-modal.outsideStateModal href="#" @click.prevent="showOutsideState($event)">{{
        i18n['tt-location'].tcAddACampOutsideOf }} {{ homeState.display_text }}</b-link>
    </p>
    <OutsideState @selectNewCamp="handleCampChange($event)" :i18n="i18n" />
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions } from 'vuex'
import { modalMixin } from '@/mixins/modalMixin'
import OutsideState from '@/components/treasury-tools/outside_state'

export default {
  components: {
    OutsideState
  },
  name: 'Location',
  mixins: [modalMixin],
  props: {
    i18n: {
      type: Object,
      default: () => {
        return {
          'tt-location': {}
        }
      }
    },
    locations: {
      type: Array,
      default: []
    },
    outside: {
      type: Boolean,
      default: false
    },
    remittance: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      show: true
    }
  },
  computed: {
    ...mapGetters({
      currentCamp: 'campModule/currentCamp',
      homeState: 'campModule/homeState'
    }),
    showDropdown() {
      return this.show
    }
  },
  methods: {
    ...mapActions({}),
    async handleCampChange(evt) {
      this.$emit('camp_change', evt)
    },
    showOutsideState(evt) {
      this.showModal(evt, 'outsideStateModal')
    }
  }
}
</script>

<style>
.custom-checkbox {
  width: 140px;
  display: inline-block;
}

.outside {
  font-weight: 700;
  color: #17a2b8;
}

.po {
  line-height: 15px;
  margin-bottom: 0;
  margin-top: 4px;
  font-size: 14px;
}
</style>
