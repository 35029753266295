<template>
  <b-modal
    class="bmodal"
    size="lg"
    id="uploadFileModal"
    ref="modalUploadFile"
    :title="i18n['upload-file'].tcUploadAFile"
    ok-only
    :no-close-on-backdrop="noCloseOnBackdrop"
    :ok-title="i18n['upload-file'].tcOk"
    @ok="handleFormSubmit($event)"
    @show='openModal()'
  >
    <b-form @submit.stop.prevent="handleFormSubmit($event)">
      <b-container fluid>
        <b-row>
          <b-col sm="4">
            {{ i18n['upload-file'].tcDocumentMonth }}
            <b-form-select
              v-model="selectedUploadMonth"
              :options="availableMonthsTranslated"
              :aria-placeholder="i18n['upload-file'].tcSelectAMonth"
            ></b-form-select>
          </b-col>
          <b-col sm="6">
            {{ i18n['upload-file'].tcSelectAFileToUpload }}
            <b-form-file
              :disabled="!selectedUploadMonth"
              ref="fileUpload"
              v-model.lazy="form.file"
              hide-footer
              name="file"
              id="uploadfile"
              :browse-text="i18n['upload-file'].tcBrowse"
              :placeholder="i18n['upload-file'].tcChooseAFile"
              :drop-placeholder="i18n['upload-file'].tcDropFileHere"
            />
          </b-col>
        </b-row>
        <b-row v-if="!!responseMessage">
          <b-col sm="12" class="msg">{{ responseMessage }}</b-col>
        </b-row>
        <b-row v-if="!responseMessage && !!waitmsg">
          <b-col sm="12" class="msg">{{ waitmsg }}</b-col>
        </b-row>
      </b-container>
    </b-form>
  </b-modal>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions } from 'vuex'
import { numericMixin } from '@/mixins/numericMixin'
import { staffUserMixin } from '@/mixins/staffUserMixin'

export default {
  name: 'UploadFile',
  props: {
    i18n: {
      type: Object,
      default: () => {
        return {
          'upload-file': {
            tcBrowse: 'Browse',
            tcChooseAFile: 'Choose a file...',
            tcDocumentMonth: 'Document month:',
            tcDropFileHere: 'Drop file here...',
            tcOk: 'Ok',
            tcSelectAFileToUpload: 'Select a file to upload...',
            tcSelectAMonth: 'Select a month',
            tcUploadAFile: 'Upload a File',
          },
        }
      },
    },
    fiscalYear: {
      type: Number,
      default: 0,
    },
  },
  mixins: [numericMixin, staffUserMixin],
  data() {
    return {
      availableMonthsTranslated: [],
      form: {
        file: null,
      },
      noCloseOnBackdrop: true,
      selectedUploadMonth: null,
      waitmsg: null,
    }
  },
  created() {
    this.getAvailableMonths({
      org_key: this.stateOrCamp === 'c' ? this.homeCamp.key : this.homeState.value,
      fy: this.fiscalYear,
    })
  },
  computed: {
    ...mapGetters({
      availableMonths: 'activityModule/availableMonths',
      currentCamp: 'campModule/currentCamp',
      getUserKey: 'userModule/getUserKey',
      homeCamp: 'campModule/homeCamp',
      homeState: 'campModule/homeState',
      monthBacKeyArray: 'activityModule/monthBacKeyArray',
      prefCulture: 'user/userPreferredCulture',
      responseMessage: 'activityModule/responseMessage',
      stateOrCamp: 'campModule/stateOrCamp',
      userId: 'user/userId',
    }),
  },
  methods: {
    ...mapActions({
      getAvailableMonths: 'activityModule/getAvailableMonths',
      uploadFile: 'activityModule/uploadFile',
    }),
    closeModal() {
      this.$refs.modalUploadFile.hide()
    },
    async handleFormSubmit(e) {
      e.preventDefault()
      this.waitmsg = this.i18n['upload-file'].tcPleaseWaitForUpload
      const bacKey = this.monthBacKeyArray.filter((mba) => {
        return mba.month === this.selectedUploadMonth
      })[0].bac_key

      const goodUpload = await this.uploadFile({
        file: this.form.file,
        bac_key: bacKey,
        ind_key: this.userId,
        staff_flag: this.isStaffMember,
      })
      if (goodUpload) {
        this.waitmsg = null
        this.$emit('refresh_upload_list')
        this.closeModal()
      }
      this.selectedUploadMonth = null
    },
    openModal() {
      this.availableMonthsTranslated = this.availableMonths.map(mth => {
        if (!mth) return mth
        const dateFormatShort = { year: 'numeric', month: 'long' }  // `May 2021`  OR  `mayo de 2021`,
        let text = ''
        if (mth.text === 'Select a month') text = this.i18n['upload-file'].tcSelectAMonth
        else {
          try {
            const tempDate = mth.text.trim().split(' ')  // 'May 2021'
            const newDate = new Date(tempDate[1], mth.value - 1, 1)
            text = newDate.toLocaleString(this.prefCulture, dateFormatShort)
          } catch (e) {
            console.error('Error in upload.vue, created(), ', e)
            text = mth.text
          }
        }
        return { ...mth, text}
      })
    },
  },
}
</script>

<style scoped>
.msg {
  color: #ff0000;
  font-weight: 600;
  line-height: 22px;
  margin-top: 5px;
}
</style>
